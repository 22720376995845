
.container {
  /* padding: 0; */
  height: 100%;
  min-height: 100vh;
  width: 100%;
  /* background: linear-gradient(to right, #9900ff, #cc80ff); */
  background: radial-gradient(transparent, black), url(./marc-olivier-jodoin-TStNU7H4UEE-unsplash.jpg); 
  position: absolute;
  top: 45px;
  z-index: -2;
  background-size: cover;
  
  /* background-color: ; */
  display: flex;
  justify-content: left;
  align-items: center;
}



.innerBox {
  min-width: 480px;
  height: fit-content;
  width: fit-content;
  margin-left: 2.55%;
  margin-bottom: 2.55%;
  background: transparent;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, .2);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  padding: 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  
}

.headingBox {
  text-align: center;
  color: #f8f9f5;
  margin-top: 10px;
  margin-bottom: 43px;
  letter-spacing: .5px;
}

.heading{
  font-family: "Lisu Bosa";
  font-size: 4rem;
  margin-bottom: 8px;
}

.subheading{
  font-family: "Lisu Bosa";
  padding: 0;
  font-size: 2rem;
}

.innerBox span label{
  position: absolute;
  margin-left: 25px;
  font-size: small;
  color: #f8f9f5;
  letter-spacing: 1px; 
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.footer .error {
  padding: 0;
  letter-spacing: 1px;
  font: 1.5rem 'Arial';
  color: #ff8060;
  text-align: center;
}

.footer button {
  outline: none;
  border: none;
  letter-spacing: 1px;
  background-color: #9900ff;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 2rem;
  padding: 15px 16px;
  width: 100%;
  transition: 150ms;
  cursor: pointer;
  
}

.footer button:disabled {
  background-color: gray !important;
}

.footer button:hover {
  background-color: #9b23ec;
}

.footer p {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  color: #000;
}

.footer p span a {
  font-weight: bold;
  color: #9900ff;
  letter-spacing: 1px;
  font-size: 1rem;
  text-decoration: none;
  font-size: 1.9rem;
}

.verse{
  position: absolute;
  padding-left: 50%;
  right : 0.2em ;
  font-family: 'Play';
  color: rgb(0, 63, 73);
  text-align: center;
  font-weight: -4rem;
  user-select: none; 
}

.toto {
  color: #dedede;
  font-size: 26px;
  font-family: 'Anonymous Pro', monospace;
  border-right: 1px solid #dedede;
  white-space: nowrap;
  overflow: hidden;
  animation: typewriter 8s steps(50) 0.5s 1 normal both, cursorblink 0.5s infinite;
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 14.5em;
  }
}
@keyframes cursorblink {
  from {
    border-right: 1px solid #dedede;
  }
  to {
    border-right: 1px solid #666;
  }
}





.TYP {
  font-size: 4rem;
}

