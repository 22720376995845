body {
  color: white;
}

.homeContainer {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background: #0D0907; */
  background: #0c0908;
  /* background: rgb(77, 81, 125);
  background: radial-gradient(circle, rgba(77, 81, 125, 1) 33%, rgba(52, 49, 107, 1) 61%, rgba(46, 61, 80, 1) 88%, rgba(53, 65, 112, 1) 100%); */
}

.buttonsContainer {
  margin: 100px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.buttonsContainer button, input {
  padding: 5px 25px;
  margin: 25px;
}

.uploadImageContainer {
  min-height: 350px;
  display: grid;
  place-items: center;
}

.uploadedImages {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.uploadedImages button {
  width: 200px;
}

.ChooseFile{
  background: none;
  font-size: 1.2em;
  color: #ffa260;
  border: 2px solid;
  padding: 0.5em 1.5em;
  transition: color 0.25s, border-color 0.25s, box-shadow 0.25s, transform 0.25s;
}
.ChooseFile:hover{
  border-color: #f1ff5c;
  color: aliceblue;
  box-shadow: 0 0.5em 0.5em -0.4em #f1ff5c;
  transform: translateY(-0.25em);
  cursor: pointer;
}


input[type=file]{
  display: none;
}

.uploadbtn{
  background: none;
  font-size: 1.2em;
  color: #ffa260;
  border: 2px solid;
  padding: 0.5em 1.5em;
  transition: color 0.25s, border-color 0.25s, box-shadow 0.25s, transform 0.25s;
}
.uploadbtn:hover{
  border-color: #f1ff5c;
  color: aliceblue;
  box-shadow: 0 0.5em 0.5em -0.4em #f1ff5c;
  transform: translateY(-0.25em);
  cursor: pointer;
}

button.pid {
  background: none;
  font-size: 1.75em;
  color: #ffa260;
  border: 2px solid;
  padding: 0.5em 1.5em;
  transition: color 0.25s, border-color 0.25s, box-shadow 0.25s, transform 0.25s;
}

button.pid:hover{
  border-color: #f1ff5c;
  color: aliceblue;
  box-shadow: 0 0.75em 0.75em -0.4em #f1ff5c;
  transform: translateY(-0.3em);
  cursor: pointer;
}