.wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}

.Info{
    padding: 15px;
    background-color: #16181c48;
    border-radius: 10%;
}

.Info h3{
    opacity: 60%;
    border-radius: 10%;
}

.Info p{
    font-size: 20px; 
    font-family: "Dosis";
    margin: 0;
    padding: 5px;
}

.Link {
    color: #1E90FF;
    font-size: 18px;
    letter-spacing: 0.025em;
}


